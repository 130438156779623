/* eslint-disable camelcase */
import React from 'react'
import { Wrapper, LogoImg, TextContainer, Text, BackLink } from '../styles/404.style'
import logoImg from '../images/logo_zaat_orignal.png'
import LayoutV2 from '../components_v2/Layout'
import SEO from '../components_v2/seo'

const Page_404 = () => (
  <LayoutV2>
    <SEO title="Page Not Found 404" />
    <Wrapper>
      <LogoImg src={logoImg} alt="zaat" />
      <TextContainer>
        <Text>
          <span>404</span>
          Page Not Found
        </Text>
      </TextContainer>
      <BackLink to="/">Go Back To Home Page</BackLink>
    </Wrapper>
  </LayoutV2>
)

export default Page_404
